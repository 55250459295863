import React from "react"

import Lottie, { LottieProps } from "react-lottie-player"

type PropTypes = LottieProps & {
  url: string
  preserveAspectRatioOpt?: string
}

function RenderLottie({ url, preserveAspectRatioOpt, ...props }: PropTypes) {
  const defaultOptions = {
    loop: true,
    play: true,
    path: url,
    animationData: null,
    rendererSettings: {
      progressiveLoad: true,
      preserveAspectRatio: preserveAspectRatioOpt,
    },
    ...props,
  }

  return <Lottie {...defaultOptions} />
}

export default RenderLottie
